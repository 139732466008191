import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePropertyIdAlways } from 'src/hooks/usePropertyId';

interface RedirectProps {
  to: string;
}

// This is necessary because `<Navigate to="/properties/:propertyId/foo" />` cannot interpolate the `:propertyId` dynamic segment of the URL
// We must manually replace `:propertyId` with the actual property ID.

export const PropertyRedirect: React.FC<RedirectProps> = ({ to }) => {
  const propertyId = usePropertyIdAlways();

  return <Navigate to={to.replace(':propertyId', propertyId)} />;
};
