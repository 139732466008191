import { matchPath, useLocation } from 'react-router';

export const usePropertyId = () => {
  const { pathname } = useLocation();
  const match = matchPath({ path: '/properties/:id/*' }, pathname);

  return match?.params.id;
};

export const usePropertyIdAlways = () => {
  const id = usePropertyId();
  if (!id) {
    throw new Error('usePropertyIdAlways() called outside of property path');
  }
  return id;
};

export const usePropertyIdAlwaysInt = () => {
  const id = usePropertyIdAlways();
  const intId = parseInt(id);
  if (Number.isNaN(intId) || intId < 1) {
    throw new Error('Cannot convert property ID into an integer');
  }
  return intId;
};
