import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBoolean } from 'react-use';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Paragraph,
} from '@qga/roo-ui/components';
import { IconCircle } from 'src/primitives/IconCircle';
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';
import useAuth from 'src/hooks/useAuth';
import ResetPasswordForm from './components/ResetPasswordForm';

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { confirmResetPassword, signOut } = useAuth();
  const [success, setSuccess] = useBoolean(false);

  const title = 'Choose a new password';
  const locationState = location.state as { email: string };

  useEffect(() => {
    if (success) {
      signOut();
    }
  }, [success, signOut]);

  useEffect(() => {
    if (!locationState || !locationState.email) {
      navigate('/forgot-password', {
        state: {
          notification:
            'Something went wrong, please request a new password reset link.',
        },
      });
    }
  }, [locationState, navigate]);

  const resetPassword = async (
    email: string,
    code: string,
    password: string,
  ) => {
    await confirmResetPassword(email, String(code), password);
    setSuccess(true);
  };

  return (
    <UnauthenticatedLayout title={title} showBanners>
      <Container maxWidth="wide">
        <Flex justifyContent="center" flexWrap="wrap">
          <Box width={[1, 1, 5 / 6]}>
            {success ? (
              <>
                <Heading.h2 mt={8} mb={4}>
                  Password successfully reset
                </Heading.h2>

                <Flex alignItems="center" flexWrap="wrap">
                  <IconCircle name="done" size={60} color="ui.success" />

                  <Box width={4 / 5}>
                    <Paragraph ml={4} mb={0}>
                      You have successfully reset your password.
                      <br />
                      Please use the new password when signing in.
                    </Paragraph>
                  </Box>
                </Flex>

                <Box width={[1, 3 / 10]}>
                  <Link to="/">
                    <Button variant="primary" block={true} mt={8}>
                      Sign in
                    </Button>
                  </Link>
                </Box>
              </>
            ) : (
              <>
                <Heading.h2 mt={8} mb={6}>
                  {title}
                </Heading.h2>

                <Box>
                  <ResetPasswordForm
                    valueType={'email'}
                    value={locationState.email}
                    onResetPassword={resetPassword}
                  />
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Container>
    </UnauthenticatedLayout>
  );
};
