import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { PropertyLayout } from 'src/layouts/PropertyLayout';

interface RouteProps {
  propertyLayout?: boolean;
  children: React.ReactElement<any, any> | null;
}

export const PrivateRoute: React.FC<RouteProps> = ({
  propertyLayout = false,
  children,
}) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const fromLocation = location.pathname;

  const forceResetPassword = isAuthenticated && user?.passwordHasExpired;

  useEffect(() => {
    if (forceResetPassword) {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'force reset pw',
        level: 'info',
      });
      navigate('/forgot-password', {
        replace: true,
        state: { forcedPasswordReset: true },
      });
    }
  }, [forceResetPassword, navigate]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      console.warn('REDIRECTING TO SIGN IN');

      navigate('/sign-in', { state: { from: fromLocation } });
    }
  }, [fromLocation, navigate, isAuthenticated, isLoading]);

  if (forceResetPassword || !isAuthenticated) {
    return null;
  }

  if (propertyLayout) {
    return <PropertyLayout>{children}</PropertyLayout>;
  }

  return children;
};
