import { ApolloProvider } from '@apollo/client';
import { CacheProvider as EmotionCacheProvider } from '@emotion/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@qga/roo-ui';
import { ModalGlobalStyle } from '@qga/roo-ui/components';

import { DisableInputSpinners } from 'src/components/DisableInputSpinners';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { AppRoutes } from 'src/components/AppRoutes';
import { UnsupportedBrowserNotice } from 'src/components/UnsupportedBrowserNotice';
import { AppViewStateProvider } from 'src/hooks/useAppViewState';
import { AuthProvider } from 'src/hooks/useAuth';
import { PropertyProvider } from 'src/hooks/useProperty';
import { AnalyticsProvider } from 'src/hooks/useAnalytics';
import { FeatureFlagProviderOptimizely } from 'src/lib/featureFlagsOptimizley';

type Props = {
  apolloClient: React.ComponentProps<typeof ApolloProvider>['client'];
  emotionCache: React.ComponentProps<typeof EmotionCacheProvider>['value'];
  theme: React.ComponentProps<typeof ThemeProvider>['theme'];
};
const App: React.FC<Props> = (props) => (
  <>
    <Helmet
      titleTemplate="%s – Qantas Hotels Extranet"
      defaultTitle="Qantas Hotels Extranet"
    />

    <ModalGlobalStyle />
    <DisableInputSpinners />

    <ApolloProvider client={props.apolloClient}>
      <AuthProvider>
        <EmotionCacheProvider value={props.emotionCache}>
          <ThemeProvider theme={props.theme}>
            <BrowserRouter>
              <FeatureFlagProviderOptimizely>
                <ErrorBoundary>
                  <AppViewStateProvider>
                    <PropertyProvider>
                      <AnalyticsProvider>
                        <UnsupportedBrowserNotice />
                        <AppRoutes />
                      </AnalyticsProvider>
                    </PropertyProvider>
                  </AppViewStateProvider>
                </ErrorBoundary>
              </FeatureFlagProviderOptimizely>
            </BrowserRouter>
          </ThemeProvider>
        </EmotionCacheProvider>
      </AuthProvider>
    </ApolloProvider>
  </>
);

export default App;
