import styled from '@emotion/styled/macro';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, ListItem } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import BareList from 'src/primitives/BareList';
import { useAnalytics } from 'src/hooks/useAnalytics';

export interface INavItemProps {
  name: string;
  title?: string;
  icon: string;
  to: string;
  exact?: boolean;
  children?: Array<{
    name: string;
    title?: string;
    to: string;
  }>;
}

const ChildList = styled(BareList)`
  margin: 0;
`;

const Underline = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin: ${themeGet('space.1')} 0;
  border-top: ${themeGet('borders.2')} transparent;
  border-bottom: ${themeGet('borders.2')} transparent;
`;

const Link = styled(NavLink)<React.ComponentProps<typeof NavLink>>`
  display: block;
  padding: ${themeGet('space.3')} ${themeGet('space.5')};
  text-decoration: none;
  color: ${themeGet('colors.greys.alto')};
  font-size: ${themeGet('fontSizes.md')};

  &:hover,
  &:focus,
  &.active {
    color: ${themeGet('colors.white')};
    outline: none;

    ${Underline} {
      border-bottom-color: ${themeGet('colors.brand.primary')};
    }
  }

  ~ ${ChildList} {
    display: none;
  }

  &.active {
    font-weight: ${themeGet('fontWeights.bold')};

    ~ ${ChildList} {
      display: block;
    }
  }
`;

const ChildLink = styled(Link)<React.ComponentProps<typeof Link>>`
  padding: ${themeGet('space.2')} ${themeGet('space.15')};
  color: ${themeGet('colors.greys.alto')};
`;

const NavItem = ({
  name,
  title,
  icon,
  to,
  exact,
  children = [],
}: INavItemProps) => {
  const { trackNavMenuClick } = useAnalytics();

  const handleMenuClick = useCallback(
    (text: string, url: string) => {
      trackNavMenuClick({
        groupName: 'Main Nav',
        text: text,
        url: url,
      });
    },
    [trackNavMenuClick],
  );

  return (
    <ListItem>
      <Link
        to={to}
        title={title ?? name}
        onClick={(event) => handleMenuClick(name, event.currentTarget.href)}
      >
        <Icon name={icon} mr={4} />
        <Underline>{name}</Underline>
      </Link>

      <ChildList>
        {children.map((item) => (
          <ListItem key={item.name}>
            <ChildLink
              to={item.to}
              title={item.title ?? item.name}
              onClick={(event) =>
                handleMenuClick(
                  `${name}_${item.name}`,
                  event.currentTarget.href,
                )
              }
            >
              {item.name}
            </ChildLink>
          </ListItem>
        ))}
      </ChildList>
    </ListItem>
  );
};

export default NavItem;
