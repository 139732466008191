import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { LoginRedirector } from 'src/components/LoginRedirector';
import { useLegacyRedirects } from 'src/hooks/useLegacyRedirects';
import { KeyContacts } from 'src/pages/KeyContacts';
import {
  CreatePromotion,
  EditPromotion,
  PromotionIndex,
} from 'src/pages/Promotions';
import { ContactUs } from 'src/pages/ContactUs';
import {
  AllImages,
  EditImages,
  PropertyImages,
  RoomTypesImages,
  UploadImages,
} from 'src/pages/MediaLibrary';
import ForgotPassword from 'src/pages/ForgotPassword';
import { Inclusions } from 'src/pages/Inclusions';
import { NoMatch } from 'src/pages/NoMatch';
import { PropertyDashboard } from 'src/pages/PropertyDashboard';
import { PropertyFacilities } from 'src/pages/PropertyFacilities';
import { PropertyGroupDashboard } from 'src/pages/PropertyGroupDashboard';
import { PropertyLocation } from 'src/pages/PropertyLocation';
import { PropertyOverview } from 'src/pages/PropertyOverview';
import {
  CreateRatePlan,
  EditRatePlan,
  RatePlansOverview,
} from 'src/pages/RatePlans';
import { BulkUpdateRates, UpdateRates } from 'src/pages/RatesAndInventory';
import { ResetPassword } from 'src/pages/ResetPassword';
import { SignIn } from 'src/pages/SignIn';
import { SignOut } from 'src/pages/SignOut';
import { Terms } from 'src/pages/Terms';
import { ReviewUserDetails, UpdateUserDetails } from 'src/pages/UserDetails';
import { VerifyEmail } from 'src/pages/VerifyEmail';
import { Vip } from 'src/pages/Vip';
import { PrivateRoute } from './components/PrivateRoute';
import { PropertyRedirect } from './components/PropertyRedirect';
import { ApiHealthCheck } from 'src/pages/ApiHealthCheck';
import { Reservations } from 'src/pages/Reservations';
import { Reservation } from 'src/pages/Reservation';
import useProperty from 'src/hooks/useProperty';

export const AppRoutes = () => {
  const property = useProperty();
  const legacyRedirection = useLegacyRedirects();

  return (
    <Routes>
      {legacyRedirection}
      {/* NOTE: redirect from HPP sign in path */}
      <Route path="/users/sign_in" element={<Navigate to="/sign-in" />} />

      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sign-out" element={<SignOut />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/vip" element={<Vip />} />

      <Route
        path="/properties/:propertyId/contacts/:contactType"
        element={
          <PrivateRoute propertyLayout>
            <KeyContacts />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/contacts"
        element={
          <PropertyRedirect to="/properties/:propertyId/contacts/primary" />
        }
      />

      <Route
        path="/properties/:propertyId/reservations/:hotelRef"
        element={
          <PrivateRoute propertyLayout>
            <Reservation />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/reservations"
        element={
          <PrivateRoute propertyLayout>
            <Reservations />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/promotions/create/*"
        element={
          <PrivateRoute propertyLayout>
            <CreatePromotion />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/promotions/:id/*"
        element={
          <PrivateRoute propertyLayout>
            <EditPromotion />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/promotions"
        element={
          <PrivateRoute propertyLayout>
            <PromotionIndex />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/rate-plans/overview"
        element={
          <PrivateRoute propertyLayout>
            <RatePlansOverview />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/rate-plans/create/*"
        element={
          <PrivateRoute propertyLayout>
            <CreateRatePlan />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/rate-plans/:id/*"
        element={
          <PrivateRoute propertyLayout>
            <EditRatePlan />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/rate-plans"
        element={
          <PropertyRedirect to="/properties/:propertyId/rate-plans/overview" />
        }
      />

      {property.ratesServiceEnabled && (
        <Route
          path="/properties/:propertyId/rates-and-inventory/:roomTypeId/bulk-update"
          element={
            <PrivateRoute propertyLayout>
              <BulkUpdateRates />
            </PrivateRoute>
          }
        />
      )}

      {property.ratesServiceEnabled && (
        <Route
          path="/properties/:propertyId/rates-and-inventory/:roomTypeId"
          element={
            <PrivateRoute propertyLayout>
              <UpdateRates />
            </PrivateRoute>
          }
        />
      )}

      <Route
        path="/properties/:propertyId/details/overview"
        element={
          <PrivateRoute propertyLayout>
            <PropertyOverview />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/details/location"
        element={
          <PrivateRoute propertyLayout>
            <PropertyLocation />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/details/facilities"
        element={
          <PrivateRoute propertyLayout>
            <PropertyFacilities />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/inclusions"
        element={
          <PrivateRoute propertyLayout>
            <Inclusions />
          </PrivateRoute>
        }
      />

      <Route
        path="/properties/:propertyId/images/property"
        element={
          <PrivateRoute propertyLayout>
            <PropertyImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/images/room-types"
        element={
          <PrivateRoute propertyLayout>
            <RoomTypesImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/images/:section/upload"
        element={
          <PrivateRoute propertyLayout>
            <UploadImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/images/:section/edit"
        element={
          <PrivateRoute propertyLayout>
            <EditImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/images/all"
        element={
          <PrivateRoute propertyLayout>
            <AllImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/properties/:propertyId/images"
        element={
          <PropertyRedirect to="/properties/:propertyId/images/property" />
        }
      />

      <Route
        path="/properties/:propertyId/details"
        element={
          <PropertyRedirect to="/properties/:propertyId/details/overview" />
        }
      />
      <Route
        path="/properties/:propertyId/*"
        element={
          <PrivateRoute propertyLayout>
            <PropertyDashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/property-groups/:propertyGroupId"
        element={
          <PrivateRoute>
            <PropertyGroupDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-details/update"
        element={
          <PrivateRoute>
            <UpdateUserDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-details"
        element={
          <PrivateRoute>
            <ReviewUserDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/verify-email"
        element={
          <PrivateRoute>
            <VerifyEmail />
          </PrivateRoute>
        }
      />

      {/* api-health-check hidden route from users. can only access by manually typing url into nav */}
      <Route
        path="/api-health-check"
        element={
          <PrivateRoute>
            <ApiHealthCheck />
          </PrivateRoute>
        }
      />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <LoginRedirector />
          </PrivateRoute>
        }
      />

      <Route element={<NoMatch />} />
    </Routes>
  );
};
