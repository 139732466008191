import React from 'react';

import { HttpRedirect } from 'src/components/HttpRedirect';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import buildHppUrl, { buildHppAssetUrl } from 'src/lib/buildHppUrl';
import { generatePath, useParams } from 'react-router';

type Props = {
  to: string;
  asset?: boolean;
};

export const HppRedirect = ({ to, asset = false }: Props) => {
  const params = useParams();
  const buildUrl = asset ? buildHppAssetUrl : buildHppUrl;

  return (
    <HttpRedirect to={buildUrl(generatePath(to, params))}>
      <LoadingOverlay />
    </HttpRedirect>
  );
};
