import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import {
  Box,
  Divider,
  Flex,
  Icon,
  NakedButton,
  Text,
} from '@qga/roo-ui/components';
import { useSelect } from 'downshift';
import React, {
  ComponentProps,
  useMemo,
  useCallback,
  MouseEventHandler,
} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { themeGet } from 'styled-system';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { Avatar } from 'src/primitives/Avatar';
import { useAnalytics } from 'src/hooks/useAnalytics';

type AppLinkType = {
  highlighted?: string;
  color?: string;
};

const AppLink = styled(RouterLink)<AppLinkType>`
  color: ${(props) =>
    themeGet(props.color ? props.color : 'colors.greys.charcoal')};
  display: block;
  padding: ${themeGet('space.2')} ${themeGet('space.6')};
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: ${themeGet('colors.greys.porcelain')};
  }

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${themeGet(props.highlighted)(props)};
    `}
`;

const CategoryLabel = styled(Box)`
  color: ${themeGet('colors.greys.dusty')};
  margin: ${themeGet('space.1')} ${themeGet('space.6')};
  text-transform: uppercase;
`;

type Link = {
  id: number;
  linkProps: ComponentProps<typeof AppLink>;
};

function useHref() {
  const location = useLocation();
  return useMemo(() => {
    const href = window.location.href.replace(window.location.origin, '');
    // adding a needless reference to location to avoid exhaustive hooks lint error
    // without explicitly disabling the link rule
    href.indexOf(location.pathname);
    return href;
  }, [location]);
}

export const ProfileMenu: React.FC = () => {
  const user = useCurrentUser();
  const href = useHref();
  const { trackNavMenuClick } = useAnalytics();

  const handleMenuClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      trackNavMenuClick({
        groupName: 'User Nav',
        text: event.currentTarget.innerText,
        url: event.currentTarget.href,
      });
    },
    [trackNavMenuClick],
  );

  const links = useMemo<Link[]>(() => {
    return [
      {
        id: 1,
        linkProps: {
          to: { pathname: '/user-details/update' },
          state: { from: href },
          children: 'Update details',
        },
      },
      { id: 2, linkProps: { to: '/contact-us', children: 'Contact support' } },
      { id: 3, linkProps: { to: '/terms', children: 'Terms of use' } },
      {
        id: 4,
        linkProps: {
          to: { pathname: '/sign-out' },
          state: { from: window.location.pathname },
          children: 'Sign out',
        },
      },
    ];
  }, [href]);

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items: links,
  });

  return (
    <>
      <NakedButton aria-label={'toggle menu'} {...getToggleButtonProps()}>
        <Flex id="profileMenu" alignItems="center">
          <Avatar />
          <Text ml={2}>{user.name}</Text>

          <Icon name="keyboardArrowDown" />
        </Flex>
      </NakedButton>

      <Box
        bg="white"
        borderRadius="default"
        boxShadow="0px 1px 15px 0px #0000001A"
        position="absolute"
        right="35px"
        width="245px"
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            <AppLink
              {...links[0].linkProps}
              {...getItemProps({
                highlighted:
                  highlightedIndex === 0 ? 'colors.greys.porcelain' : undefined,
                index: 0,
                item: links[0],
              })}
              onClick={handleMenuClick}
            />

            <div aria-labelledby="profile-menu-resource-group" role="group">
              <CategoryLabel id="profile-menu-resource-group">
                Resources
              </CategoryLabel>

              <AppLink
                {...links[1].linkProps}
                {...getItemProps({
                  highlighted:
                    highlightedIndex === 1
                      ? 'colors.greys.porcelain'
                      : undefined,
                  index: 1,
                  item: links[1],
                })}
                onClick={handleMenuClick}
              />

              <AppLink
                {...links[2].linkProps}
                {...getItemProps({
                  highlighted:
                    highlightedIndex === 2
                      ? 'colors.greys.porcelain'
                      : undefined,
                  index: 2,
                  item: links[2],
                })}
                onClick={handleMenuClick}
              />
            </div>

            <Divider />

            <AppLink
              color="colors.red"
              {...links[3].linkProps}
              {...getItemProps({
                highlighted:
                  highlightedIndex === 3 ? 'colors.greys.porcelain' : undefined,
                index: 3,
                item: links[3],
              })}
              onClick={handleMenuClick}
            />
          </>
        )}
      </Box>
    </>
  );
};
